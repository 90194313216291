import { BASE_API_URL } from '@/constants/api'
import axios, { AxiosInstance, AxiosPromise, Cancel } from 'axios'
import {
  ApiRequestConfig,
  WithAbortFn,
  ApiExecutor,
  ApiExecutorArgs,
  ApiError,
} from './api.types'

// Default config for the axios instance
const axiosParams = {
  // Set different base URL based on the environment
  baseURL: BASE_API_URL,
}

export const baseAPIURL = BASE_API_URL
// Create axios instance with default params
export const axiosInstance = axios.create(axiosParams)

export const didAbort = (
  error: unknown
): error is Cancel & { aborted: boolean } => axios.isCancel(error)

const getCancelSource = () => axios.CancelToken.source()

export const isApiError = (error: unknown): error is ApiError => {
  return axios.isAxiosError(error)
}

const withLogger = async <T>(promise: AxiosPromise<T>) =>
  promise.catch((error: ApiError) => {
    if (process.env.NODE_ENV !== 'development') throw error

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data, 'DATA RECEIVED')
      console.log(error.response.status, 'STATUS RECEIVED')
      console.log(error.response.headers, 'HEADERS RECEIVED')
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest
      // in the browser and an instance of
      // http.ClientRequest in node.js console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
    console.log(error.config)

    throw error
  })

const withAbort = <T>(fn: WithAbortFn) => {
  const executor: ApiExecutor<T> = async (...args: ApiExecutorArgs) => {
    const originalConfig = args[args.length - 1] as ApiRequestConfig // Extract abort property from the config
    const { abort, ...config } = originalConfig
    // Create cancel token and abort method only if abort // function was passed
    if (typeof abort === 'function') {
      const { cancel, token } = getCancelSource()
      config.cancelToken = token
      abort(cancel)
    }
    try {
      if (args.length > 2) {
        const [url, body] = args
        return await fn<T>(url, body, config)
      } else {
        const [url] = args
        return await fn<T>(url, config)
      }
    } catch (error) {
      console.log('api error', error)
      // Add "aborted" property to the error if the request was cancelled
      if (didAbort(error)) {
        error.aborted = true
      }
      throw error
    }
  }
  return executor
}

// Main api function
const api = (axios: AxiosInstance) => {
  return {
    get: <T>(url: string, config: ApiRequestConfig = {}) => {
      return withLogger<T>(
        withAbort<T>(axios.get)(url, {
          withCredentials: true,

          ...config,
        })
      )
    },
    delete: <T>(url: string, config: ApiRequestConfig = {}) => {
      return withLogger<T>(
        withAbort<T>(axios.delete)(url, {
          withCredentials: true,

          ...config,
        })
      )
    },
    post: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) => {
      return withLogger<T>(
        withAbort<T>(axios.post)(url, body, {
          withCredentials: true,

          ...config,
        })
      )
    },
    patch: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) => {
      return withLogger<T>(
        withAbort<T>(axios.patch)(url, body, {
          withCredentials: true,

          ...config,
        })
      )
    },
    put: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) => {
      return withLogger<T>(
        withAbort<T>(axios.put)(url, body, {
          withCredentials: true,

          ...config,
        })
      )
    },
  }
}
export default api(axiosInstance)
