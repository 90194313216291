import { useCallback, useEffect, useId, useMemo, useState } from 'react'
import { contextFactory } from '@/contexts/helpers/contextFactory'
import { makeId } from '../helpers'

type TabContextValues = {
  activeTab: string
  tabsId: string
}

type TabContextActions = {
  setActiveTab: (current: string) => void
  setTabsId: (current: string) => void
  isActive: (value: string) => boolean
}
const [useTabValuesContext, TabValuesContext] =
  contextFactory<TabContextValues>()
const [useTabActionsContext, TabActionsContext] =
  contextFactory<TabContextActions>()

export { useTabActionsContext, useTabValuesContext }
interface Props {
  children: React.ReactNode
  value?: string
  defaultValue?: string
  name?: string
  onChange?: (e?: {
    target: {
      value: string
      name?: string
    }
    currentTarget: {
      value: string
      name?: string
    }
  }) => {}
}

const TabContextProvider = (props: Props) => {
  const { children, value, defaultValue, onChange, name } = props
  const [activeTab, setActiveTab] = useState(value || defaultValue || '')
  const [tabsId, setTabsId] = useState(makeId('Tabs', useId()))
  const isActive = useCallback(
    (value: string) => {
      return value === activeTab
    },
    [activeTab]
  )
  const values = useMemo(
    () => ({
      activeTab,
      tabsId,
    }),
    [activeTab, tabsId]
  )
  const actions = useMemo(
    () => ({
      setActiveTab,
      setTabsId,
      isActive,
    }),
    [isActive]
  )
  useEffect(() => {
    onChange?.({
      target: {
        name,
        value: activeTab,
      },
      currentTarget: {
        name,
        value: activeTab,
      },
    })
  }, [activeTab, name, onChange])
  return (
    <TabValuesContext.Provider value={values}>
      <TabActionsContext.Provider value={actions}>
        {' '}
        {children}
      </TabActionsContext.Provider>{' '}
    </TabValuesContext.Provider>
  )
}

export default TabContextProvider
