import { PolymorphicComponentProps } from '@/types/polymorphic.type'
import clsx from 'clsx'
import { forwardRef } from 'react'

type TabPanelsProps<C extends React.ElementType> = PolymorphicComponentProps<
  C,
  {
    children: React.ReactNode
    className?: string
  }
>
const TabPanels = forwardRef<
  React.ElementType,
  TabPanelsProps<React.ElementType>
>(({ as: Element = 'div', children, className, ...others }, ref) => {
  const INTERNAL_CLASSNAME = 'Tabs__tabPanels'
  const Component = Element || 'div'
  return (
    <Component
      className={clsx(INTERNAL_CLASSNAME, className || '')}
      ref={ref}
      {...others}
    >
      {children}
    </Component>
  )
})

TabPanels.displayName = 'TabPanels'

export default TabPanels
