import Typography from '@/common/Typography'
import { Colors } from '../constants'
import Button from '@/common/button/Button'
import { Startup } from '@/apis/startupApis'
import { currencyFormat } from '@/helpers/strings'

const InterestedCard = (props: { data: Startup; onView: () => void }) => {
  const SelectedColor = Colors[Math.floor(Math.random() * Colors.length)]
  const { data, onView } = props
  return (
    <section className='flex w-[368px] max-w-full flex-col items-start p-6 gap-4 bg-sifuse-shades-50 rounded-lg border border-sifuse-shades-200'>
      <main className='flex flex-col items-start gap-3 self-stretch w-full'>
        <ul className='flex flex-wrap gap-2 items-start content-start w-full'>
          <li>
            <ProductType
              bgColor={SelectedColor.bgColor}
              color={SelectedColor.textColor}
              text={data.industry}
            />
          </li>
        </ul>
        <Typography as='h5' label='lg' color='var(--shades800)'>
          {data.name}
        </Typography>
        {/* {data.funding && (
          <hgroup className='flex items-center gap-1'>
            <Typography
              as='strong'
              className='text-center text-sm font-medium leading-5'
            >
              ${currencyFormat(data.funding.target_amount)}
            </Typography>
            <Typography as='p' paragraph='xs' color='var(--shades600)'>
              Target Raise
            </Typography>
          </hgroup>
        )} */}
      </main>
      <footer className='w-full justify-self-end flex-1 flex flex-col justify-end'>
        <Button
          color='white'
          size='xs'
          className='!border-2 !border-sifuse-main-primary w-full'
          onClick={onView}
        >
          View Business
        </Button>
      </footer>
    </section>
  )
}

const ProductType = (props: {
  bgColor: string
  color: string
  text: string
}) => {
  return (
    <span
      style={{
        background: props.bgColor,
        color: props.color,
      }}
      className='flex py-0.5 px-2 rounded-sm uppercase tracking-[0.5px] leading-4 font-medium text-[10px] justify-center items-center gap-2'
    >
      {props.text}
    </span>
  )
}
export default InterestedCard
