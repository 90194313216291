import iconComponents from '@/assets/icons/iconComponents'
import React from 'react'
import Typography from '../Typography'
import clsx from 'clsx'

type IModalHeader = {
  onClose?: () => void
  title?: React.ReactNode
  subText?: React.ReactNode
  hasUnderline?: boolean
}
const ModalHeader = (props: IModalHeader) => {
  const { onClose, title, subText, hasUnderline = true } = props
  return (
    <header
      className={clsx(
        'flex flex-col justify-start items-start w-full p-6',
        hasUnderline ? 'border-b border-sifuse-shades-200' : ''
      )}
    >
      <div className='w-full flex justify-between items-center'>
        <Typography as='h4' heading='xs' className='text-sifuse-shades-900'>
          {title}
        </Typography>
        {onClose && (
          <button
            onClick={onClose}
            className='p-1 rounded-sm hover:bg-[#f7f7f7] active:bg-[#f7f7f7] w-[28px] h-[28px] sm:w-[37px] sm:h-[37px]'
          >
            <iconComponents.util.CancelIcon
              width={24}
              height={24}
              stroke='var(--shades900)'
            />
          </button>
        )}
      </div>
      {subText && (
        <Typography
          as='p'
          paragraph='sm'
          className='!text-sifuse-shades-500 sm font-normal pr-8'
        >
          {subText}
        </Typography>
      )}
    </header>
  )
}

export default ModalHeader
