import { PolymorphicComponentProps } from '@/types/polymorphic.type'
import clsx from 'clsx'

type TypoProps<C extends React.ElementType> = PolymorphicComponentProps<
  C,
  {
    children: React.ReactNode
    className?: string
    variant?: 'primary' | 'link'
    display?: 'lg' | 'md' | 'sm' | 'xs'
    heading?: '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | '2xs' | '3xs' | '4xs'
    paragraph?: 'lg' | 'md' | 'sm' | 'xs'
    button?: 'lg' | 'md' | 'sm' | 'xs'
    label?: 'lg' | 'md' | 'sm' | 'xs'
  }
>
const StyleMaps = {
  display: {
    lg: 'font-bold text-[96px] leading-[112px]',
    md: 'font-medium text-[52px] leading-[64px]',
    sm: 'font-medium text-[44px] leading-[52px]',
    xs: 'font-medium text-[36px] leading-[44px]',
  },
  heading: {
    '2xl': 'font-bold text-[40px] leading-[52px]',
    xl: 'font-bold text-[36px] leading-[44px]',
    lg: 'font-medium text-[32px] leading-[40px]',
    md: 'font-medium text-[28px] leading-[36px]',
    sm: 'font-medium text-[24px] leading-[32px]',
    xs: 'font-medium text-[20px] leading-[28px]',
    '2xs': 'font-medium text-[18px] leading-[24px]',
    '3xs': 'font-medium text-[16px] leading-[22px]',
    '4xs': 'font-medium text-[14px] leading-[22px]',
  },
  label: {
    lg: 'font-medium text-[18px] leading-[24px]',
    md: 'font-normal text-[16px] leading-[20px]',
    sm: 'font-normal text-[14px] leading-[16px]',
    xs: 'font-normal text-[12px] leading-[16px]',
  },
  paragraph: {
    lg: 'font-normal text-[18px] leading-[26px]',
    md: 'font-normal text-[16px] leading-[24px]',
    sm: 'font-normal text-[14px] leading-[16px]',
    xs: 'font-normal text-[12px] leading-[16px]',
  },
  button: {
    lg: 'font-medium text-[18px] leading-[26px]',
    md: 'font-medium text-[16px] leading-[24px]',
    sm: 'font-medium text-[14px] leading-[16px]',
    xs: 'font-medium text-[12px] leading-[16px]',
  },
}
function Typography<C extends React.ElementType = 'div'>(props: TypoProps<C>) {
  const {
    children,
    as,
    className,
    variant,
    display,
    paragraph,
    heading,
    button,
    label,
    color = 'var(--shades900)',
    ...divProps
  } = props
  const Component = as || 'div'
  return (
    <Component
      style={{
        color: color,
      }}
      className={clsx(
        display && StyleMaps.display[display],
        heading && StyleMaps.heading[heading],
        paragraph && StyleMaps.paragraph[paragraph],
        button && StyleMaps.button[button],
        label && StyleMaps.label[label],
        className
      )}
      {...divProps}
    >
      {children}{' '}
    </Component>
  )
}

export default Typography
