import useAutomaticRefresh from '@/apis/hooks/useAutomaticRefresh'
import useRefreshToken from '@/apis/hooks/useRefreshToken'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import useNetworkStatus from '@/hooks/useNetworkStatus'
import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router'
import { HashLoader } from 'react-spinners'

const ProtectedRoutes = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isDone] = useAutomaticRefresh()
  const isOnline = useNetworkStatus()
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const { getAccessToken } = useAuthContext()

  const refresh = useRefreshToken()
  useEffect(() => {
    if (!isDone) return
    if (window.location.pathname.includes('auth')) {
      setIsLoading(false)
      return
    }
    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (err) {
        console.error(err, 'HERE IS MY ERROR')
      } finally {
        setIsLoading(false)
      }
    }
    !isOnline
      ? setIsLoading(false)
      : getAccessToken()
      ? setIsLoading(false)
      : verifyRefreshToken()
  }, [getAccessToken, isDone, isOnline, refresh])

  return isLoading ? (
    <div className='w-screen h-screen bg-white flex justify-center items-center'>
      <HashLoader color='#EBC345' loading={isLoading} size={110} />
    </div>
  ) : getAccessToken() ? (
    <Outlet />
  ) : (
    <Navigate
      to={`/auth/signin`}
      state={{ from: location.pathname + (location.search || '') }}
      replace
    />
  )
}

export default ProtectedRoutes
