import clsx from 'clsx'
import React from 'react'
import Typography from '../Typography'

type Props = {
  label?: React.ReactNode
  id: string
  subtext?: React.ReactNode
  children: any
  className?: string
  required?: boolean
}
function FormGroup({
  children,
  label,
  id,
  subtext,
  className,
  required,
}: Props) {
  return (
    <div
      className={clsx(
        'sm sm:md focus-within:text-sifuse-shades-900 flex flex-col text-sifuse-shades-500 active:text-sifuse-shades-900 gap-2 w-full text-sm',
        className
      )}
    >
      <div className='w-full flex justify-between items-center md'>
        <Typography
          htmlFor={id}
          as='label'
          label='sm'
          className='text-sifuse-shades-900 text-sm leading-4 font-medium'
        >
          {label} {required && <span className='text-[#F04438]'>*</span>}
        </Typography>
        <span className='text-xs font-light leading-5'>{subtext}</span>
      </div>
      {React.cloneElement(children, {
        id,
      })}
    </div>
  )
}

export default FormGroup
