import { useCallback } from 'react'
import { getAccessToken } from '../authApis'
import { useAuthActionsContext } from '@/contexts/AuthContextProvider'

const useRefreshToken = () => {
  const { setAccessToken } = useAuthActionsContext()
  const refresh = useCallback(async () => {
    const { data } = await getAccessToken()
    setAccessToken(data.data.access)
    return data.data.access
  }, [setAccessToken])
  return refresh
}

export default useRefreshToken
