import { Outlet } from 'react-router'
import Header from './components/header'
import LaptopMenu from './components/menus/LaptopMenu'

const ApplicationLayout = () => {
  return (
    <article className='w-full bg-sifuse-background h-[100svh] overflow-hidden flex flex-col xl:grid grid-cols-[240px,_minmax(20px,_1fr)] grid-rows-[64px,_minmax(100px,_1fr)]'>
      <header className='w-full row-start-1 row-span-1 col-start-2 col-span-1 bg-white border-b border-[#e6e6eb]'>
        <Header />
      </header>
      <aside className='hidden xl:flex col-start-1  col-span-1 row-start-1 row-span-2 bg-white border-r border-[#e6e6eb] '>
        <LaptopMenu />
      </aside>
      <main className='flex-1 overflow-auto p-7 px-5 md:px-6 xl:p-8'>
        <Outlet />
      </main>
    </article>
  )
}

export default ApplicationLayout
