import { NOTIFICATION } from '@/apis/endpoints'
import iconComponents from '@/assets/icons/iconComponents'
import { WEB_SOCKET_URL } from '@/constants/api'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import { Pages } from '@/routes/route.constant'
import { useGetUnreadNotifcationCount } from '@/store/notificationStore'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router'
import { w3cwebsocket as W3CWebSocket } from 'websocket'

const Notifications = () => {
  const { getAccessToken } = useAuthContext()
  const queryClient = useQueryClient()
  const token = getAccessToken()
  const { pathname } = useLocation()
  const { data: unreadCount } = useGetUnreadNotifcationCount()
  useEffect(() => {
    let client
    try {
      client = new W3CWebSocket(`${WEB_SOCKET_URL}/ws/notification/${token}/`)
    } catch (error: any) {
      console.log(error.message, "Couldn't create connection")
    }
    if (!client) return
    client.onopen = () => {
      console.log('WebSocket Client Connected TO Notifications')
    }
    client.onmessage = (ev: any) => {
      let dataFromServer
      console.log(ev.data, 'RECEIVED')
      try {
        dataFromServer = JSON.parse(ev.data)
        if (dataFromServer) {
          const { action } = dataFromServer
          console.log(dataFromServer, 'DATA')
          if (action) {
            queryClient.invalidateQueries(NOTIFICATION)
          }
        }
      } catch (err) {
        console.log(ev.data, 'DATA FROM SERVER')
      }
    }
  }, [token, queryClient])
  const isNotifications = pathname.includes(`app/${Pages.Notification}`)
  return (
    <span className='flex gap-3 items-center cursor-pointer relative'>
      <iconComponents.navigation.NotificationIcon />
      {!!unreadCount?.unread_count && (
        <span
          className={clsx(
            'inline-flex justify-center items-center gap-0.5 rounded-full w-3 h-3 border border-sifuse-shades-50 bg-[var(--mainred)] absolute -top-0.5 -right-[1px]',
            isNotifications && '!border-sifuse-main-primary'
          )}
        ></span>
      )}
    </span>
  )
}

export default Notifications
