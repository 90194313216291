import { useCallback, useMemo, useState } from 'react'
import { contextFactory } from '@/contexts/helpers/contextFactory'

type DescendantContextValues = {
  keys: string[]
}

type DescendantContextActions = {
  register: (key: string) => void
  deregister: (key: string) => void
  getIndex: (key: string) => number
}
const [useDescendantValuesContext, DescendantValuesContext] =
  contextFactory<DescendantContextValues>()
const [useDescendantActionsContext, DescendantActionsContext] =
  contextFactory<DescendantContextActions>()

export { useDescendantActionsContext, useDescendantValuesContext }
interface Props {
  children: React.ReactNode
}

const DescendantContextProvider = (props: Props) => {
  const { children } = props
  const [keys, setKeys] = useState<string[]>([])
  const register = useCallback((key: string) => {
    setKeys((keys) => keys.filter(getFilterKeys(key)).concat(key))
  }, [])
  const deregister = useCallback((key: string) => {
    setKeys((keys) => keys.filter(getFilterKeys(key)))
  }, [])
  const getIndex = useCallback(
    (key: string) => {
      return keys.indexOf(key)
    },
    [keys]
  )

  const values = useMemo(
    () => ({
      keys,
    }),
    [keys]
  )
  const actions = useMemo(
    () => ({
      register,
      deregister,
      getIndex,
    }),
    [register, deregister, getIndex]
  )
  return (
    <DescendantValuesContext.Provider value={values}>
      <DescendantActionsContext.Provider value={actions}>
        {' '}
        {children}
      </DescendantActionsContext.Provider>{' '}
    </DescendantValuesContext.Provider>
  )
}

function getFilterKeys(key: string) {
  return (k: string) => k !== key
}

export default DescendantContextProvider
