import InterestedStartups from './components/InterestedStartups'
import ExploreStartups from './components/ExploreStartups'
import { Pages } from '@/routes/route.constant'
import { useBreadCrumbs } from '@/layouts/app/components/header'

const BreadCrumbMap = new Map()
BreadCrumbMap.set(`/app/${Pages.StartupDiscovery}`, 'Startup Discovery')
const StartupDiscovery = () => {
  useBreadCrumbs(BreadCrumbMap)

  return (
    <article className='flex flex-col gap-[26px] w-full flex-1 items-center md:items-stretch'>
      <InterestedStartups />
      <ExploreStartups />
    </article>
  )
}

export default StartupDiscovery
