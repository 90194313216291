import iconComponents from '@/assets/icons/iconComponents'
import Typography from '@/common/Typography'

const EmptyScreen = (props?: { title?: string; subText?: string }) => {
  const {
    title = ' No Businesses to Explore',
    subText = 'You dont have any business to explore',
  } = props || {}
  return (
    <div className='w-full rounded-lg py-20 min-h-[50svh] border border-sifuse-shades-200 h-full flex-1 flex flex-col gap-6 justify-center items-center bg-sifuse-shades-50'>
      <iconComponents.util.EmptyMessageIcon />
      <hgroup className='flex flex-col gap-1 items-center justify-center'>
        <Typography as='h4' heading='xs'>
          {title}
        </Typography>
        <Typography as='p' heading='4xs' color='#979797'>
          {subText}
        </Typography>
      </hgroup>
    </div>
  )
}

export default EmptyScreen
