import OrgDetails from './components/AsOrg.OrgDetails'
import IndividualDetails from './components/AsOrg.IndividualDetails'
import useStepper from '@/hooks/useStepper'
import Typography from '@/common/Typography'
import { FC } from 'react'
import { Storage } from '@/helpers/Storage'

const ORG_STEP = 'OrgStep'
function SignUpAsOrg() {
  const { step, goToNextStep, goToPrevStep } = useStepper(
    Storage.getItem(ORG_STEP) || 1
  )

  const StepMap: Record<number, any> = {
    1: {
      Comp: (
        <OrgDetails
          handleNext={() => {
            Storage.setItem(ORG_STEP, step + 1)
            goToNextStep()
          }}
        />
      ),
      description: 'Detail Information',
    },
    2: {
      Comp: (
        <IndividualDetails
          handlePrev={() => {
            Storage.setItem(ORG_STEP, step - 1)
            goToPrevStep()
          }}
        />
      ),
      description: 'Personal Information',
    },
  }

  return (
    <div className='w-full flex flex-col gap-6'>
      <Headerline description={StepMap[step].description} step={step} />
      {StepMap[step].Comp}
    </div>
  )
}

const Headerline: FC<{ description: string; step: number }> = (props) => {
  const { description, step } = props
  return (
    <Typography className='flex justify-between items-center'>
      <Typography label='lg'>{description}</Typography>
      <Typography paragraph='lg'>{step}/2</Typography>
    </Typography>
  )
}
export default SignUpAsOrg
