import { PaginationReq } from '@/apis/api.types'
import { INVESTOR_GROUPS } from '@/apis/endpoints'
import {
  CreateInvestorGroup,
  GetAccounts,
  InvestorGroupResDTO,
  RemoveInvestorGroupLogo,
  UpdateInvestorGroup,
} from '@/apis/investorGroupApis'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'

export const useCreateInvestorGroup = () => {
  const qc = useQueryClient()
  return useMutation(CreateInvestorGroup, {
    onSuccess() {
      qc.invalidateQueries([INVESTOR_GROUPS])
    },
  })
}
export const useUpdateInvestorGroup = (
  onSuccess: (data: InvestorGroupResDTO) => void
) => {
  const qc = useQueryClient()
  return useMutation(UpdateInvestorGroup, {
    onSuccess(data) {
      qc.invalidateQueries([INVESTOR_GROUPS])
      onSuccess(data.data.data)
    },
  })
}
export const useRemoveInvestorGroupLogo = (onSuccess: () => void) => {
  const qc = useQueryClient()
  return useMutation(RemoveInvestorGroupLogo, {
    onSuccess() {
      qc.invalidateQueries([INVESTOR_GROUPS])
      onSuccess()
    },
  })
}

export const useGetInvestorGroups = (data: PaginationReq) => {
  return useInfiniteQuery(
    [INVESTOR_GROUPS, data],
    ({ pageParam = 1 }) => GetAccounts({ ...data, page: pageParam }),
    {
      getNextPageParam(lastPage, allPages) {
        if (!lastPage.next) return undefined
        const page = new URL(String(lastPage.next)).searchParams.get('page')
        return page
      },
      getPreviousPageParam: (firstPage, allPages) => {
        if (!firstPage.previous) return undefined
        const page = new URL(String(firstPage.previous)).searchParams.get(
          'page'
        )
        return page
      },
    }
  )
}
