import { useAuthContext } from '@/contexts/AuthContextProvider'
import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router'

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.classList.add('scroller')
    document.body.classList.add('scrollbar')
  }, [pathname])

  return null
}
const PublicRoutes = () => {
  const { pathname } = useLocation()
  const { getAccessToken } = useAuthContext()
  const accessToken = getAccessToken()
  const isAuthPage = pathname.split('/').includes('auth')
  return isAuthPage || !accessToken ? (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  ) : (
    <Navigate to={`/success`} />
  )
}

export default PublicRoutes
