import Image from '@/common/Image'
import { Outlet } from 'react-router'

const SuccessLayout = () => {
  return (
    <article className='flex flex-col h-[100svh] w-full py-10 gap-10 md:py-16'>
      <header className='w-full flex items-center justify-center md:h-[232px] '>
        <div className='hidden md:flex  justify-center items-center  '>
          <Image
            src='/logo.png'
            className='w-[49px] h-[55px] md:w-[79px] md:h-[90px]'
          />
        </div>
      </header>
      <main className='flex-1 w-full '>
        <Outlet />
      </main>
    </article>
  )
}

export default SuccessLayout
