import { addDataToQueryString } from '@/helpers/strings'
import api from './api'
import { PaginatedData, PaginationReq, ServerRes } from './api.types'
import {
  CONNECTIONS,
  GET_STARTUPS,
  GET_STARTUPS_HIGHLIGHTS,
  GET_STARTUPS_THAT_MATCH_CRITERIA,
  STARTUP_ASSESSMENT,
  STARTUP_ASSESSMENT_DATA,
} from './endpoints'

export type Startup = {
  id: number
  name: string
  country: string
  members?: Member[]
  logo?: string
  industry: string
  product_type: string
  is_registered: boolean
  about_us: string
  website: string
  mission?: string
  vision?: string
  is_founder?: boolean
  is_published?: boolean
  bio_video?: string
  created?: string
  updated?: string
  assessment?: AssessmentResDTO
  funding?: Funding[]
  position?: string
  has_data_room_access?: boolean
  percentage_match?: number
  has_interest?: boolean
  num_interested_investors: number
  score: string | null
  screening_result: Record<string, string> | null
}

export type Funding = {
  funding_round: string
  target_amount: number
  raised_amount: number
  investors: number
  valuation: string
  min_investment: string
  share_price: string
  shares_offered: string
  offering_type: string
  offering: string
  offering_max: string
  startup?: number
  id?: number
}
export type AssessmentReqDTO = {
  problem_and_vision: number[]
  market: number[]
  product: number[]
  value_preposition: number[]
  team: number[]
  company_age: number[]
  business_model: number[]
  sdg: number[]
  investment_type: number[]
  gender_lens: number[]
  traction: number[]
  exit_strategy: number[]
  scale: number[]
  startup?: number
  id?: number
}

export type AssessmentResDTO = {
  problem_and_vision: AssessmentOption[]
  market: AssessmentOption[]
  product: AssessmentOption[]
  value_preposition: AssessmentOption[]
  team: AssessmentOption[]
  company_age: AssessmentOption[]
  business_model: AssessmentOption[]
  sdg: AssessmentOption[]
  investment_type: AssessmentOption[]
  gender_lens: AssessmentOption[]
  traction: AssessmentOption[]
  exit_strategy: AssessmentOption[]
  scale: AssessmentOption[]
  startup?: number
  id?: number
}

export interface AssessmentOption {
  id: number
  has_stars: boolean
}

export type AssessmentData = {
  problem_and_vision: Options[]
  market: Options[]
  product: Options[]
  value_preposition: Options[]
  team: Options[]
  company_age: Options[]
  business_model: Options[]
  sdgs: Options[]
  investment_type: Options[]
  gender_lens: Options[]
  traction: Options[]
  exit_strategy: Options[]
  scale: Options[]
}

type Options = Record<number, string>

export type Member = {
  id: number
  first_name: string
  last_name: string
  avatar: string
  about: string
  startup: number
  is_founder: boolean
  is_active: boolean
  num_connections: number
  connection: Connection | null
  position?: string
}
export type Connection = {
  id: number
  status: 'Pending' | 'Accepted' | 'Declined'
  from_user: Member
  to_user: Member
}
export type Highlight = {
  startup: number
  content: string
  id: number
}
export const Startups = async (data: PaginationReq) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<ServerRes<PaginatedData<Startup>>>(
      GET_STARTUPS + (qs ? `?${qs}` : '')
    )
  ).data.data
}
export const StartupsThatMatchCriteria = async (data: PaginationReq) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<ServerRes<PaginatedData<Startup>>>(
      GET_STARTUPS_THAT_MATCH_CRITERIA + (qs ? `?${qs}` : '')
    )
  ).data.data
}

export const StartupDetail = async (data: { startup: number }) => {
  return (await api.get<ServerRes<Startup>>(GET_STARTUPS + `${data.startup}/`))
    .data.data
}

export const Hightlights = async (data: { startup: number }) => {
  return (
    await api.get<ServerRes<Array<Highlight>>>(
      GET_STARTUPS_HIGHLIGHTS + `?startup=${data.startup}`
    )
  ).data.data
}

export const CreateConnection = async (data: { to_user: number }) => {
  return await api.post<ServerRes<Connection>>(CONNECTIONS, data)
}

export const ModifyInterest = async (data: { startup: number }) => {
  return await api.post<ServerRes<Connection>>(
    GET_STARTUPS + `${data.startup}/investor-interest/`,
    data
  )
}

export const GetAssessmentData = async () => {
  return (await api.get<ServerRes<AssessmentData>>(STARTUP_ASSESSMENT_DATA))
    .data.data
}
export const GetAssessment = async (data: { assessment: number }) => {
  return (
    await api.get<ServerRes<Required<AssessmentResDTO>>>(
      `${STARTUP_ASSESSMENT}${data.assessment}/`
    )
  ).data.data
}

export const CreateAssessment = async (
  data: Required<Omit<AssessmentReqDTO, 'id' | 'startup'>>
) => {
  return await api.post<ServerRes<Required<AssessmentResDTO>>>(
    STARTUP_ASSESSMENT,
    data
  )
}
export const UpdateAssessment = async (
  data: Partial<AssessmentReqDTO> & { id: number }
) => {
  return await api.patch<ServerRes<Required<AssessmentResDTO>>>(
    `${STARTUP_ASSESSMENT}${data.id}/`,
    data
  )
}
