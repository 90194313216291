import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@/common/tabs'
import clsx from 'clsx'
import React, { ReactElement, useEffect } from 'react'

import Title from '@/common/Title'
import SignUpAsIndiv from './SignUpAsIndiv'
import SignUpAsOrg from './SignUpAsOrg'
import { useNavigate } from 'react-router'
import { Pages } from '@/routes/route.constant'
import { Storage } from '@/helpers/Storage'

type TabNames = 'individual' | 'organisation'
const tabNames: Record<TabNames, string> = {
  individual: 'As an Individual',
  organisation: 'As Group/Network/Syndicate',
}
const ORG_STEP = 'OrgStep'
function SignUp() {
  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = React.useState<TabNames>(
    Storage.getItem(ORG_STEP) ? 'organisation' : 'individual'
  )

  const TabElements: Record<TabNames, () => ReactElement> = {
    individual: SignUpAsIndiv,
    organisation: SignUpAsOrg,
  }

  return (
    <>
      <article className='w-full flex-1 flex flex-col gap-16 md:px-[92px] md:pt-6 pb-10 overflow-auto '>
        <header className='w-full flex items-center justify-center'>
          <Title>Sign Up</Title>
          <hgroup className='flex flex-col justify-center items-center'>
            <Typography as='h1' heading='xl' className='text-center'>
              Sign Up
            </Typography>
          </hgroup>
        </header>

        <main>
          <Tabs
            onChange={(e: any) => {
              setSelectedTab(e.currentTarget.value)
            }}
            value={selectedTab}
            className='w-full gap-7 flex flex-col'
          >
            <TabList className='flex  gap-1.5 items-center self-stretch w-full sm:min-w-[360px]'>
              {Object.keys(tabNames).map((t) => (
                <Tab as='span' key={t} value={t} className='flex-1'>
                  <Button
                    className={clsx(
                      'flex-1 text-center w-full !rounded-none',
                      selectedTab === t
                        ? 'border-b-2 border-primary'
                        : 'leading-5 !font-normal !border-none !text-black-white-shades-3 '
                    )}
                    size='md'
                    color={'plain'}
                  >
                    <Typography
                      as='span'
                      label='lg'
                      className='whitespace-nowrap normal-case'
                    >
                      {tabNames[t as TabNames]}
                    </Typography>
                  </Button>
                </Tab>
              ))}
            </TabList>

            <TabPanels className='m-0'>
              {Object.keys(tabNames).map((t) => {
                const Comp = TabElements[t as TabNames]

                return (
                  <TabPanel key={t} value={t} className=''>
                    <Comp />
                  </TabPanel>
                )
              })}
            </TabPanels>
          </Tabs>
        </main>
        <footer className='w-full flex self-stretch items-end justify-center gap-1 flex-1'>
          <Typography
            button='md'
            color='var(--shades500)'
            className='text-sifuse-shades-500'
          >
            Already have an account ?
          </Typography>
          <button
            type='button'
            onClick={() => navigate(`/auth/${Pages.SignIn}`)}
            className='hover:underline font-semibold text-base leading-6 text-sifuse-main-secondary'
          >
            Sign in
          </button>
        </footer>
      </article>
    </>
  )
}

export default SignUp
