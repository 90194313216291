import useLogout from '@/apis/hooks/useLogout'
import { InvestorGroupResDTO } from '@/apis/investorGroupApis'
import iconComponents from '@/assets/icons/iconComponents'
import InfiniteScroll, { InfiniteScrollResult } from '@/common/InfiniteScroll'
import Typography from '@/common/Typography'
import Avatar from '@/common/avatar/Avatar'
import {
  useAuthActionsContext,
  useAuthContext,
} from '@/contexts/AuthContextProvider'
import { useComposeRefs } from '@/hooks/useComposeRefs'
import useGetCssValue from '@/hooks/useGetCssValue'
import useMediaQuery from '@/hooks/useMediaQuery'
import { useOutsideClick } from '@/hooks/useOutsideClick'
import { Pages } from '@/routes/route.constant'
import { useGetInvestorGroups } from '@/store/investorGroupStore'
import clsx from 'clsx'
import { MouseEventHandler, forwardRef, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

const UserInfo = forwardRef<HTMLDivElement, { onClose?: () => void }>(
  (props, ref) => {
    const navigate = useNavigate()
    const { getAuthState, currentAccount } = useAuthContext()
    const { switchAccount } = useAuthActionsContext()
    const userState = getAuthState()!
    const logout = useLogout()

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const outerRef = useRef<HTMLDivElement>(null)
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    anchorRef.current = anchorEl

    const clickHandler: MouseEventHandler = (e) => {
      e.preventDefault()
      if (anchorEl) {
        setAnchorEl(null)
      } else setAnchorEl(e.currentTarget as HTMLButtonElement)
    }
    useOutsideClick([anchorRef, outerRef], () => setAnchorEl(null))
    const combinedRef = useComposeRefs(outerRef, ref)
    const logoutHandler = async () => {
      setAnchorEl(null)
      logout()
    }
    const { data: allPages } = useGetInvestorGroups({})
    return (
      <section
        ref={combinedRef}
        className='w-full flex h-12 px-4 gap-2 items-center cursor-pointer  bg-white max-w-min shrink-0'
      >
        <hgroup className='flex items-center gap-2'>
          <Avatar
            size='sm'
            notification
            notificationClassName={
              currentAccount.accountType === 'user' ? ' right-0' : ''
            }
            type={currentAccount.accountType === 'user' ? 'rounded' : 'square'}
            src={
              currentAccount.accountType === 'user'
                ? userState?.avatar || '/defaultImage.svg'
                : currentAccount.logo || '/defaultCompany.svg'
            }
            className={
              currentAccount.accountType === 'organisation'
                ? 'bg-sifuse-main-secondary'
                : ''
            }
          />
          <div className='flex flex-col justify-center items-start'>
            <Typography
              as='h6'
              button='sm'
              className='whitespace-nowrap text-ellipsis overflow-hidden'
            >
              {currentAccount.accountType === 'user'
                ? `${userState?.first_name} ${userState?.last_name}`
                : currentAccount.name}
            </Typography>
            <Typography
              as='p'
              paragraph='xs'
              color='var(--shades700)'
              className='capitalize'
            >
              {currentAccount.accountType === 'user'
                ? userState?.user_type
                : currentAccount.role?.name}
            </Typography>
          </div>
        </hgroup>
        <button onClick={clickHandler} className='p-3'>
          <iconComponents.navigation.DropdownNavIcon />
        </button>
        {Boolean(anchorEl) && (
          <ul
            className={clsx(
              'flex flex-col items-start shadow-lg z-50 gap-1 w-[208px] bg-sifuse-shades-50 rounded-lg py-2.5 px-4  absolute bottom-6 left-[144px]  md:top-16 md:right-4 md:left-auto xl:left-[148px] xl:right-0 xl:top-auto xl:bottom-6 h-max',
              Boolean(anchorEl) ? 'flex' : 'hidden'
            )}
          >
            {allPages?.pages?.[0]?.results?.length !== 0 &&
              allPages?.pages?.[0]?.results.filter(
                (el) => !(el.blocked || el.investor_blocked)
              ).length !== 0 && (
                <>
                  <SwitchAccount />
                  {currentAccount.accountType === 'organisation' && (
                    <Typography
                      label='sm'
                      as='li'
                      className='h-12 flex items-center cursor-pointer'
                      onClick={() => {
                        switchAccount({
                          accountType: 'user',
                          ...(userState ? userState : ({} as any)),
                        })
                        setAnchorEl(null)
                        navigate(`/app/${Pages.StartupDiscovery}`)
                      }}
                    >
                      Switch to investor
                    </Typography>
                  )}
                </>
              )}
            <li
              onClick={logoutHandler}
              className='flex items-center gap-2 h-12 cursor-pointer'
            >
              <iconComponents.navigation.LogoutIcon />
              <Typography as='button' button='sm' color='var(--shades700)'>
                Logout
              </Typography>
            </li>
          </ul>
        )}
      </section>
    )
  }
)

UserInfo.displayName = 'UserInfo'

const SwitchAccount = () => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const outerRef = useRef<HTMLLIElement>(null)
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  anchorRef.current = anchorEl

  const clickHandler: MouseEventHandler = (e) => {
    e.preventDefault()
    if (anchorEl) {
      setAnchorEl(null)
    } else setAnchorEl(e.currentTarget as HTMLButtonElement)
  }
  useOutsideClick([anchorRef, outerRef], () => setAnchorEl(null))

  const { currentAccount } = useAuthContext()
  const { switchAccount } = useAuthActionsContext()

  const [containerRef, containerHeight] = useGetCssValue(
    'height',
    '38px',
    anchorEl
  )
  const isLaptop = useMediaQuery('(min-width: 1280px)')
  return (
    <li ref={outerRef} className='flex items-center gap-2 w-full '>
      <Typography className='break-words' as='span' label='sm'>
        Switch Syndicates/Groups/ Networks
      </Typography>
      <iconComponents.util.ChevronDownIcon
        width={32}
        height={32}
        className={
          Boolean(anchorEl)
            ? 'rotate-90 md:-rotate-180 xl:rotate-90'
            : '-rotate-90 md:-rotate-0 xl:-rotate-90'
        }
        onClick={clickHandler}
      />
      {Boolean(anchorEl) && (
        <InfiniteScroll
          key={Boolean(anchorEl).toString()}
          className={clsx(
            `w-[208px] absolute top-6 left-[104px]  md:top-14 md:right-8 md:left-auto xl:left-[100%] xl:right-0`
          )}
          getInfiniteData={useGetInvestorGroups}
          data={{}}
          style={{
            ...(isLaptop
              ? {
                  top: `-${Math.floor(
                    (parseFloat(containerHeight) * 25) / 100
                  )}px`,
                }
              : {}),
          }}
        >
          {({ allPageData }: InfiniteScrollResult<InvestorGroupResDTO>) => (
            <ul
              className={clsx(
                'flex flex-col items-start shadow-lg py-2 gap-2 bg-sifuse-shades-50 rounded-lg  ',
                Boolean(anchorEl) ? 'flex' : 'hidden'
              )}
              ref={containerRef}
            >
              {allPageData
                .filter((el) => !(el.blocked || el.investor_blocked))
                .map((el) => (
                  <Typography
                    key={el.id}
                    as='li'
                    label='sm'
                    className={clsx(
                      'py-2 px-4 hover:!text-sifuse-shades-900 w-full  flex items-center cursor-pointer capitalize',
                      currentAccount.accountType === 'organisation' &&
                        currentAccount.id === el.id &&
                        '!bg-sifuse-background '
                    )}
                    onClick={() => {
                      switchAccount({ accountType: 'organisation', ...el })
                      setAnchorEl(null)
                      navigate(`/app/${Pages.StartupDiscovery}`)
                    }}
                  >
                    {el.name}
                  </Typography>
                ))}
            </ul>
          )}
        </InfiniteScroll>
      )}
    </li>
  )
}
export default UserInfo
