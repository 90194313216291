import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { useNavigate } from 'react-router'
import { useGetStartups } from '@/store/startupStore'
import ApiState from '@/common/ApiState'
import { Pages } from '@/routes/route.constant'
import InterestedCard from './InterestedCard'

const InterestedStartups = () => {
  const navigate = useNavigate()

  const { data: firstPageStartups, isLoading: isLoadingStartups } =
    useGetStartups({ page: 1, interested: true })
  return (
    <ApiState
      isLoading={isLoadingStartups}
      isEmpty={!firstPageStartups?.pages?.[0]?.count}
      emptyComponent={<div></div>}
    >
      <section className='flex w-full flex-col gap-6 '>
        <header className='flex justify-between items-center w-full'>
          <hgroup className='flex flex-col items-start gap-1'>
            <Typography as='h3' heading='sm' className='text-sifuse-shades-900'>
              Interested Businesses
            </Typography>
            <Typography as='p' paragraph='sm' color='var(--shades600)'>
              Companies you have Interested in.
            </Typography>
          </hgroup>
          <Button
            onClick={() =>
              navigate(
                `/app/${Pages.StartupDiscovery}/${Pages.InterestedStartup}`
              )
            }
            color='white'
            size='xs'
            className='w-[128px]'
          >
            View All
          </Button>
        </header>
        <main className='w-full flex flex-col'>
          <ul className='flex justify-start gap-4 overflow-x-auto w-full pb-2 scroller !scrollbar-h-1'>
            {Array.isArray(firstPageStartups?.pages?.[0]?.results) &&
              (firstPageStartups?.pages?.[0]?.results || [{} as any])
                // .slice(0, 2)
                .map((startup) => (
                  <li className=' inline-flex' key={startup.id}>
                    <InterestedCard
                      data={startup}
                      onView={() =>
                        navigate(
                          `/app/${Pages.StartupDiscovery}/${startup.name}`,
                          {
                            state: { from: 'summary', id: startup.id },
                          }
                        )
                      }
                    />
                  </li>
                ))}
          </ul>
        </main>
      </section>
    </ApiState>
  )
}

export default InterestedStartups
