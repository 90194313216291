import composeEventHandlers from '@/helpers/composeEventHandlers'
import { useComposeRefs } from '@/hooks/useComposeRefs'
import { PolymorphicComponentProps } from '@/types/polymorphic.type'
import clsx from 'clsx'
import {
  ElementType,
  forwardRef,
  KeyboardEvent,
  useEffect,
  useRef,
} from 'react'
import {
  useDescendantActionsContext,
  useDescendantValuesContext,
} from './contexts/DescendantContext'
import {
  useTabActionsContext,
  useTabValuesContext,
} from './contexts/TabsContext'
import { clamp } from './helpers'

type TabsProps<C extends React.ElementType> = PolymorphicComponentProps<
  C,
  {
    children: React.ReactNode
    className?: string
  }
>
const Tab = forwardRef<React.ElementType, TabsProps<React.ElementType>>(
  (
    {
      as: Element = 'button',
      children,
      className,
      value,
      onClick,
      onKeyDown,
      onFocus,
      ...others
    },
    forwardedRef
  ) => {
    const INTERNAL_CLASSNAME = 'Tabs__tab'
    const Component = Element || 'div'
    const { tabsId } = useTabValuesContext()
    const { setActiveTab, isActive } = useTabActionsContext()
    const { register, deregister, getIndex } = useDescendantActionsContext()
    const { keys } = useDescendantValuesContext()
    const tabId = `${tabsId}__tab__${value}`
    const panelId = `${tabsId}__panel__${value}`
    const ownRef = useRef()
    const combineRef = useComposeRefs(ownRef, forwardedRef)
    const active = isActive(value)
    useEffect(() => {
      register(value)
      return () => {
        deregister(value)
      }
    }, [value, register, deregister])

    const isMounted = useRef(false)
    useEffect(() => {
      if (!isMounted.current) {
        isMounted.current = true
        return
      }
      if (
        active &&
        (document.activeElement as unknown as ElementType<any>) !==
          ownRef.current
      ) {
        ;(ownRef.current as any)?.focus?.()
      }
    }, [active])
    const clickHandler = () => {
      setActiveTab(value)
    }
    const handleFocus = () => {
      setActiveTab(value)
    }
    const handleKeyDown = (e: any) => {
      const currentIndex = getIndex(value)
      const lastIndex = keys.length - 1
      const nextIndex = clamp(currentIndex + 1, 0, lastIndex)
      const previousIndex = clamp(currentIndex - 1, 0, lastIndex)
      switch ((e as KeyboardEvent).key) {
        case 'ArrowRight':
        case 'ArrowDown':
          setActiveTab(keys[nextIndex])
          return
        case 'ArrowLeft':
        case 'ArrowUp':
          setActiveTab(keys[previousIndex])
          return
        case 'Home':
          setActiveTab(keys[0])
          return
        case 'End':
          setActiveTab(keys[lastIndex])
          return
        default:
          return
      }
    }

    return (
      <Component
        onClick={composeEventHandlers(onClick, clickHandler)}
        onTouchStart={composeEventHandlers(onClick, clickHandler)}
        onFocus={composeEventHandlers(onFocus, handleFocus)}
        role='tab'
        id={tabId}
        aria-controls={panelId}
        className={clsx(INTERNAL_CLASSNAME, className || '')}
        ref={combineRef}
        onKeyDown={composeEventHandlers(onKeyDown, handleKeyDown)}
        aria-selected={active ? true : undefined}
        tabIndex={active ? '0' : '-1'}
        type='button'
        {...others}
      >
        {children}
      </Component>
    )
  }
)

Tab.displayName = 'Tab'

export default Tab
