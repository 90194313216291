import { PaginationReq } from '@/apis/api.types'
import { GET_UNREAD_NOTIFICATION, NOTIFICATION } from '@/apis/endpoints'
import {
  GetUnreadNotificationCount,
  MarkAllNotificationAsRead,
  Notifications,
} from '@/apis/notificationApis'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

export const useGetNotifications = (data: PaginationReq) => {
  return useInfiniteQuery(
    [NOTIFICATION, data],
    ({ pageParam }) => Notifications({ ...data, page: pageParam }),
    {
      getNextPageParam(lastPage) {
        if (!lastPage.next) return undefined
        const page = new URL(lastPage.next).searchParams.get('page')
        return page
      },
    }
  )
}

export const useMarkAllAsRead = () => {
  const qc = useQueryClient()
  return useMutation(MarkAllNotificationAsRead, {
    onSuccess() {
      qc.invalidateQueries(NOTIFICATION)
      qc.invalidateQueries(GET_UNREAD_NOTIFICATION)
    },
  })
}

export const useGetUnreadNotifcationCount = () => {
  return useQuery(GET_UNREAD_NOTIFICATION, GetUnreadNotificationCount)
}
