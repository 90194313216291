/* eslint-disable react-hooks/exhaustive-deps */
import { RouterProvider } from 'react-router-dom'

import { ToastContainer } from '@/common/toastManager/'

import { useEffect, useState } from 'react'

import HashLoader from 'react-spinners/HashLoader'
import useNetworkStatus from './hooks/useNetworkStatus'
import RouteConfig from './routes'

function App() {
  return (
    <div>
      {/* {isLoading ? (
        <div className='w-screen h-screen bg-white flex justify-center items-center'>
          <HashLoader color='#00A0AE' loading={isLoading} size={100} />
        </div>
      ) : (
        <RouterProvider router={RouteConfig} />
      )} */}
      <RouterProvider router={RouteConfig} />
      <ToastContainer />
    </div>
  )
}

export default App
