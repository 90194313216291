import { PolymorphicComponentProps } from '@/types/polymorphic.type'
import clsx from 'clsx'
import { forwardRef } from 'react'

type TabListProps<C extends React.ElementType> = PolymorphicComponentProps<
  C,
  {
    children: React.ReactNode
    className?: string
  }
>
const TabList = forwardRef<React.ElementType, TabListProps<React.ElementType>>(
  (
    {
      as: Element = 'div',
      children,
      className,

      ...others
    },
    ref
  ) => {
    const INTERNAL_CLASSNAME = 'Tabs__tablist'
    const Component = Element || 'div'

    return (
      <Component
        className={clsx(INTERNAL_CLASSNAME, className || '')}
        role='tablist'
        ref={ref}
        {...others}
      >
        {children}
      </Component>
    )
  }
)

TabList.displayName = 'TabList'

export default TabList
