import signIn from './signin.png'
import signUp from './signup.png'
import pdf from './pdf.png'
import word from './word.png'
import excel from './xls.png'
import blanckProfileImg from './blank-profile-picture.png'
import blanckCompImg from '../icons/utils/defaultCompany.svg'
import calendarEvent from './calendar-events.png'

export const imagesUrl = {
  signIn,
  signUp,
  blanckProfileImg,
  pdf,
  word,
  excel,
  blanckCompImg,
  calendarEvent,
}
