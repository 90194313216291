import { Startup } from '@/apis/startupApis'
import useMediaQuery from '@/hooks/useMediaQuery'
import { Colors } from '../constants'
import Image from '@/common/Image'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { currencyFormat } from '@/helpers/strings'

const StartupCard = (props: {
  data: Startup
  onView: () => void
  showMatch?: boolean
}) => {
  const isLaptop = useMediaQuery('(max-width: 640px)')

  const { data: startup } = props
  const SelectedColor = Colors[Math.floor(Math.random() * Colors.length)]
  return (
    <section className='w-full relative md:max-w-[368px] md:p-8 rounded-lg border border-sifuse-shades-200 bg-sifuse-shades-50 flex items-start md:flex-col self-stretch md:h-[447px]'>
      <header className='relative m-[17px_6px_6px_13px] md:m-0 md:w-full'>
        <Image
          src={startup.logo || '/defaultCompany.svg'}
          className='w-[87px] object-cover object-center h-[75px] md:w-full md:h-[135px] rounded-lg bg-sifuse-main-secondary'
        />
        {startup.funding && (
          <span className='absolute bottom-1 left-1 md:bottom-2 md:left-2 inline-flex py-0.5 px-1.5 items-start gap-1.5 rounded-[22px] bg-sifuse-main-primary text-sifuse-shades-900 text-center text-[7px] md:text-[10px]  font-medium leading-[10px] md:leading-4 uppercase tracking-[0.5px] md:tracking-[0.9px]'>
            {startup?.funding[startup?.funding?.length - 1]?.funding_round}
          </span>
        )}
      </header>
      <main className='flex  p-[16px_24px_17px_12px] md:px-0 flex-col items-start gap-3.5 flex-1 w-full'>
        <section className='flex flex-col items-start gap-1 self-stretch w-full'>
          <ProductType
            color={SelectedColor.textColor}
            bgColor={SelectedColor.bgColor}
            text={startup.product_type}
          />
          <hgroup className='flex flex-col gap-1 self-stretch'>
            <Typography
              as='h5'
              heading={isLaptop ? '3xs' : undefined}
              color='var(--shades800)'
              label={isLaptop ? undefined : 'lg'}
            >
              {startup.name}
            </Typography>
            <Typography as='p' paragraph='xs' color='var(--shades600)'>
              {startup.about_us}
            </Typography>
          </hgroup>
          {/* {startup.funding && (
            <hgroup className='flex items-center gap-1'>
              <Typography
                as='strong'
                className='text-center text-sm font-medium leading-5'
              >
                ${currencyFormat(startup.funding.target_amount)}
              </Typography>
              <Typography as='p' paragraph='xs' color='var(--shades600)'>
                Target Raise
              </Typography>
            </hgroup>
          )} */}
        </section>
        <div className='flex flex-col flex-1 justify-end w-full '>
          <Button
            onClick={props.onView}
            size='xs'
            color='white'
            className='w-[128px] mt-3  md:!w-full'
          >
            View Details
          </Button>
        </div>
      </main>
      {props.showMatch && (
        <aside className='flex flex-col justify-center items-center absolute top-0 right-0 md:top-4 md:right-[36px] lg:right-4 w-[72px] h-[72px] rounded-lg bg-[#00000051] backdrop-blur-[8px] '>
          <span className='text-sifuse-shades-50 text-xl font-bold leading-7'>
            {startup.percentage_match || 0}%
          </span>
          <span className='text-sifuse-shades-50 text-xs font-semibold leading-4'>
            Match
          </span>
        </aside>
      )}
    </section>
  )
}

const ProductType = (props: {
  bgColor: string
  color: string
  text: string
}) => {
  return props.text ? (
    <span
      style={{
        background: props.bgColor,
        color: props.color,
      }}
      className='flex py-0.5 px-2 rounded-sm uppercase tracking-[0.4px] md:tracking-[0.5px] leading-[11px] md:leading-4 font-medium text-[7px] md:text-[10px] justify-center items-center gap-2'
    >
      {props.text}
    </span>
  ) : null
}

export default StartupCard
