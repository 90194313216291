import { addDataToQueryString } from '@/helpers/strings'
import api from './api'
import { PaginatedData, PaginationReq, ServerRes } from './api.types'
import {
  INVESTOR_GROUPS,
  REMOVE_INVESTOR_GROUP_LOGO,
  UPDATE_INVESTOR_GROUP,
} from './endpoints'

export enum CategoryType {
  Group = 'Group',
  Syndicate = 'Syndicate',
  Network = 'Network',
}

export enum LegalStructureType {
  Partnership = 'Partnership',
  SoleProprietorship = 'Sole Proprietorship',
  CrowdFunding = 'Crowd Funding',
  FinancialInstitution = 'Financial Institution',
  AngelInvestors = 'Angel Investors',
  GovernmentAgencies = 'Government Agencies',
}

export interface InvestorGroupReqDTO {
  category: CategoryType
  name: string
  structure: LegalStructureType
  registration_id: string
  address: string
  email: string
  phone_number: string
  website: string
  about?: string
}

export interface InvestorGroupResDTO extends InvestorGroupReqDTO {
  id: number
  created: string
  updated: string
  role: Role
  logo?: string
  blocked: boolean
  investor_blocked: boolean
}
interface Role {
  id: number
  name: string
  permissions: number[]
}
export const CreateInvestorGroup = async (
  data: InvestorGroupReqDTO & { access?: string }
) => {
  return await api.post<ServerRes<InvestorGroupResDTO>>(
    INVESTOR_GROUPS,
    data,
    data.access
      ? {
          headers: { Authorization: `Bearer ${data.access}` },
        }
      : {}
  )
}

export const GetAccounts = async (data: PaginationReq) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<ServerRes<PaginatedData<InvestorGroupResDTO>>>(
      INVESTOR_GROUPS + (qs ? `?${qs}` : '')
    )
  ).data?.data
}

export const UpdateInvestorGroup = async (data: FormData) => {
  return await api.patch<ServerRes<InvestorGroupResDTO>>(
    UPDATE_INVESTOR_GROUP,
    data
  )
}
export const RemoveInvestorGroupLogo = async () => {
  return await api.patch<ServerRes<any>>(REMOVE_INVESTOR_GROUP_LOGO, {})
}
