import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import { useCountries } from '@/hooks/useCountryStates'
import { useToggleState } from '@/hooks/useToggleState'
import SelectInput from '@/common/Input/SelectInput'
import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button/Button'
import { useCreateAccount } from '@/store/authStore'
import { PersonalInfoReqDTO } from '@/apis/authApis'
import { personalInfoSchema } from '../validators/signup.validator'
import { FormikHelpers, useFormik } from 'formik'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'
import { Storage } from '@/helpers/Storage'
import { useCreateInvestorGroup } from '@/store/investorGroupStore'
import { useNavigate } from 'react-router'
import { Pages } from '@/routes/route.constant'

interface Props {
  handlePrev: () => void
}
declare var PasswordCredential: any

const ORG_STEP = 'OrgStep'
const IndividualDetails = ({ handlePrev }: Props) => {
  const [Countries] = useCountries()
  const navigate = useNavigate()
  const { state: showPassword, toggle: togglePassword } = useToggleState(false)
  const { mutateAsync: createAccountAction, isLoading: isCreatingAccount } =
    useCreateAccount()
  const {
    isLoading: isCreatingInvestorGroup,
    mutateAsync: createInvestorGroup,
  } = useCreateInvestorGroup()
  const INITIAL_VALUES: PersonalInfoReqDTO & {
    confirm_password: string
  } = {
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    phone_number: '234\t',
    confirm_password: '',
  }
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitHandler,
    validationSchema: personalInfoSchema(true),
  })
  async function submitHandler(
    values: PersonalInfoReqDTO & {
      confirm_password: string
    },
    {
      setSubmitting,
      resetForm,
    }: FormikHelpers<
      PersonalInfoReqDTO & {
        confirm_password: string
      }
    >
  ) {
    const { error, response } = await withAsync(() => {
      const phone_number =
        '+' +
        values.phone_number.split('\t')[0] +
        values.phone_number.split('\t')[1].replace(/^0?/, '')
      return createAccountAction({ ...values, phone_number })
    })
    if (error) {
      errorHandler(error)
      return setSubmitting(false)
    }
    if (response) {
      setSubmitting(false)
      resetForm()
      const groupValues = Storage.getItem('GROUP')
      const { error } = await withAsync(() =>
        createInvestorGroup({
          ...groupValues,
          access: response.data.data.access,
        })
      )

      if (error) {
        setSubmitting(false)
        return errorHandler(error)
      }
      if (typeof PasswordCredential !== 'undefined') {
        const cred = new PasswordCredential({
          id: values.email,
          password: values.password,
          name: `${values.first_name} ${values.last_name}`,
        })
        navigator.credentials.store(cred).then((creds) => {
          console.log(creds, 'CREDS')
        })
      }
      ToastNotify('info', {
        title: 'Account Created.',
        content:
          'A message has been sent to your email, kindly go and verify your email to complete account creation.',
        timeout: 60_000,
      })

      Storage.removeItem(ORG_STEP)
      Storage.removeItem('GROUP')
      navigate(`/auth/${Pages.SignIn}`)
    }
  }

  return (
    <article className='w-full flex-1 flex flex-col gap-10  overflow-auto '>
      <form
        onSubmit={handleSubmit}
        className='flex flex-col items-start gap-4 self-stretch md:grid grid-cols-2'
      >
        <FormGroup label='First name' id='first_name' className='col-span-1'>
          <InputWithAdornment
            placeholder='Enter your first name'
            autoComplete='given-name'
            onChange={handleChange}
            onBlur={handleBlur}
            name='first_name'
            value={values.first_name}
            error={
              errors.first_name &&
              (touched.first_name as unknown as string) &&
              errors.first_name
            }
          />
        </FormGroup>
        <FormGroup label='Last name' id='last_name' className='col-span-1'>
          <InputWithAdornment
            placeholder='Enter your last name'
            autoComplete='family-name'
            onChange={handleChange}
            onBlur={handleBlur}
            name='last_name'
            value={values.last_name}
            error={
              errors.last_name &&
              (touched.last_name as unknown as string) &&
              errors.last_name
            }
          />
        </FormGroup>
        <FormGroup label='Gender' id='gender' className='col-span-1'>
          <SelectInput
            placeholder='Select Gender'
            autoCorrect='gender'
            options={['Male', 'Female']}
            className='w-full'
            onChange={handleChange}
            onBlur={handleBlur}
            name='gender'
            value={values.gender}
            error={
              errors.gender &&
              (touched.gender as unknown as string) &&
              errors.gender
            }
          />
        </FormGroup>
        <FormGroup
          label='Email address'
          id='email_address'
          className='col-span-1'
        >
          <InputWithAdornment
            type='email'
            placeholder='Enter email address'
            inputMode={'email'}
            autoCorrect='username'
            onChange={handleChange}
            onBlur={handleBlur}
            name='email'
            value={values.email}
            error={
              errors.email &&
              (touched.email as unknown as string) &&
              errors.email
            }
          />
        </FormGroup>
        <FormGroup
          label='Phone number'
          id='phone_number'
          className='col-span-2'
        >
          <div className='flex items-center w-full gap-3'>
            <SelectInput
              options={Countries}
              optionsDisplay={(country) =>
                country?.emoji ? (
                  <span className='flex items-center gap-2.5'>
                    <span className='h-4 max-w-[22px]'>{country.flag}</span>
                    <span className='text-sifuse-shades-600 text-sm leading-5 '>
                      +{country?.phone}
                    </span>
                  </span>
                ) : (
                  'Country'
                )
              }
              menuClassName='!mr-0 overflow-x-hidden whitespace-normal'
              optionsText={(country) =>
                country?.emoji ? (
                  <span className='flex items-center gap-2.5'>
                    <span className='h-4 w-[22px]'>{country?.flag}</span>
                    <span>{country?.name}</span>
                    <span className='text-sifuse-shades-600 text-sm leading-5'>
                      (+{country?.phone})
                    </span>
                  </span>
                ) : (
                  'Country'
                )
              }
              optionsValue={(country) => {
                return country?.phone
              }}
              onChange={(e) => {
                const number = values.phone_number.split('\t')[1]
                setFieldValue(
                  'phone_number',
                  e.currentTarget.value + '\t' + number
                )
              }}
              placeholder='Select Country'
              value={values.phone_number.split('\t')[0]}
              error={
                errors.phone_number &&
                (touched.phone_number as unknown as string) &&
                'Phone number incorrect'
              }
            />
            <InputWithAdornment
              placeholder='Enter phone number'
              inputMode={'tel'}
              autoComplete='tel-local'
              onChange={(e) => {
                const code = values.phone_number.split('\t')[0]
                setFieldValue(
                  'phone_number',
                  code + '\t' + e.currentTarget.value
                )
              }}
              onBlur={handleBlur}
              name='phone_number'
              value={values.phone_number?.split('\t')[1]}
              error={
                errors.phone_number &&
                (touched.phone_number as unknown as string) &&
                errors.phone_number
              }
            />
          </div>
        </FormGroup>

        <FormGroup label='Passsword' id='password' className='col-span-2'>
          <InputWithAdornment
            type={showPassword ? 'text' : 'password'}
            placeholder='Enter your passsword'
            right={
              <button type='button' onClick={togglePassword}>
                {showPassword ? (
                  <iconComponents.auth.EyeCloseIcon />
                ) : (
                  <iconComponents.auth.EyeOpenIcon />
                )}
              </button>
            }
            onChange={handleChange}
            onBlur={handleBlur}
            name='password'
            value={values.password}
            error={
              errors.password &&
              (touched.password as unknown as string) &&
              errors.password
            }
          />
        </FormGroup>
        <FormGroup
          label='Confirm passsword'
          id='password_confirm'
          className='col-span-2'
        >
          <InputWithAdornment
            type={showPassword ? 'text' : 'password'}
            placeholder='Enter your passsword again'
            right={
              <button type='button' onClick={togglePassword}>
                {showPassword ? (
                  <iconComponents.auth.EyeCloseIcon />
                ) : (
                  <iconComponents.auth.EyeOpenIcon />
                )}
              </button>
            }
            onChange={handleChange}
            onBlur={handleBlur}
            name='confirm_password'
            value={values.confirm_password}
            error={
              errors.confirm_password &&
              (touched.confirm_password as unknown as string) &&
              errors.confirm_password
            }
          />
        </FormGroup>
        <Button
          type='submit'
          color='primary'
          size='xl'
          className='w-full text-center  mt-8 col-span-2'
          loading={isCreatingAccount || isSubmitting || isCreatingInvestorGroup}
          disabled={
            !isValid ||
            isSubmitting ||
            isCreatingAccount ||
            isCreatingInvestorGroup
          }
        >
          Continue & Sign up
        </Button>
        <Button
          color='white'
          size='xl'
          className='w-full text-center  mt-1 col-span-2'
          disabled={
            isSubmitting || isCreatingAccount || isCreatingInvestorGroup
          }
          onClick={handlePrev}
        >
          Back
        </Button>
      </form>
    </article>
  )
}

export default IndividualDetails
