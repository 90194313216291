export const PermissionNames = {
  'Can Manage User Roles & Permissions': 1,
  'Can View User Roles & Permissions': 2,
  'Can View Businesses': 3,
  'Can Manage Businesses': 4,
  'Can View Investors': 5,
  'Can Manage Investors': 6,
  'Can View Portfolios': 7,
  'Can Manage Portfolios': 8,
  'Can View Funds': 9,
  'Can Manage Funds': 10,
  'Can View Group Profile': 11,
  'Can Manage Group Profile': 12,
  'Can Post on Network': 13,
  'Can Receive and Reply Messages': 14,
  'Can Create Event': 15,
  'Can View Community': 16,
  'Can Manage Community': 17,
  'Can Create Connection': 18,
  'Can View Deals': 19,
  'Can Manage Deals': 20,
}
