import { PolymorphicComponentProps } from '@/types/polymorphic.type'
import clsx from 'clsx'
import { forwardRef } from 'react'
import {
  useTabActionsContext,
  useTabValuesContext,
} from './contexts/TabsContext'

type TabPanelProps<C extends React.ElementType> = PolymorphicComponentProps<
  C,
  {
    children: React.ReactNode
    className?: string
  }
>
const TabPanel = forwardRef<
  React.ElementType,
  TabPanelProps<React.ElementType>
>(({ as: Element = 'div', children, value, className, ...others }, ref) => {
  const { tabsId } = useTabValuesContext()
  const INTERNAL_CLASSNAME = 'Tabs__tabPanel'
  const tabId = `${tabsId}__tab__${value}`
  const panelId = `${tabsId}__panel__${value}`
  const Component = Element || 'div'
  const { isActive } = useTabActionsContext()
  const active = isActive(value)
  return (
    <Component
      aria-labelledby={tabId}
      id={panelId}
      className={clsx(INTERNAL_CLASSNAME, className || '')}
      role='tabpanel'
      tabIndex={active ? '0' : '-1'}
      hidden={active ? undefined : true}
      ref={ref}
      {...others}
    >
      {children}
    </Component>
  )
})

TabPanel.displayName = 'TabPanel'

export default TabPanel
