import { addDataToQueryString } from '@/helpers/strings'
import api from './api'
import { PaginatedData, PaginationReq, ServerRes } from './api.types'
import {
  GET_UNREAD_NOTIFICATION,
  MARK_NOTIFICATION_AS_READ,
  NOTIFICATION,
} from './endpoints'

export type Notification = {
  title: string
  message: string
  timestamp: string
  unread: boolean
  id: number
  type?: string
  target?: any
}
export const Notifications = async (data: PaginationReq) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<ServerRes<PaginatedData<Notification>>>(
      NOTIFICATION + (qs ? `?${qs}` : '')
    )
  ).data.data
}

export const MarkAllNotificationAsRead = async () => {
  return api.patch<ServerRes<any>>(MARK_NOTIFICATION_AS_READ, {})
}

export const GetUnreadNotificationCount = async () => {
  return (
    await api.get<ServerRes<{ unread_count: number }>>(GET_UNREAD_NOTIFICATION)
  ).data.data
}
