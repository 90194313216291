import useLockBodyScroll from '@/hooks/useLockBodyScroll'
import { useToggleState } from '@/hooks/useToggleState'
import iconComponents from '@/assets/icons/iconComponents'
import { MenuItem } from '../../dashboard.types'
import NavItem from '../NavItem'
import UserInfo from '../UserInfo'
import Chats from '../header/Chats'
import Image from '@/common/Image'
import Notifications from '../header/Notifications'
import { Pages } from '@/routes/route.constant'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import Permission from '@/common/permissions/Permission'
import { PermissionNames } from '@/common/permissions/permission.constant'

type MobileMenuProps = {
  menuOptions: MenuItem[]
}
const MobileMenu = (props: MobileMenuProps) => {
  const {
    state: showDrawer,
    open: openDrawer,
    close: closeDrawer,
  } = useToggleState(false)
  const { menuOptions } = props
  const { currentAccount } = useAuthContext()

  useLockBodyScroll(showDrawer)
  return (
    <section className='md:hidden flex-1'>
      {!showDrawer && (
        <header className='flex gap-3 items-center justify-end h-11'>
          <Chats />
          <button
            aria-controls='menu'
            onClick={openDrawer}
            className='active:scale-95'
          >
            <iconComponents.util.MenubarIcon
              stroke='#000'
              width={24}
              height={24}
            />
          </button>
        </header>
      )}

      <nav
        style={{
          display: showDrawer ? 'block' : 'none',
          transform: showDrawer ? 'translateX(0px)' : 'translateX(150vw)',
        }}
        role='menubar'
        id='menu'
        className='w-[100svw] max-h-[101svh] min-h-[100svh]  fixed top-0 left-0 bottom-0 px-5 right-0 z-30 bg-white   overflow-auto transition-transform flex flex-col gap-3.5'
      >
        <header className='w-full flex justify-between items-center h-[50px] '>
          <Image src='/logo.png' className='w-[37px] h-[42px] mx-3' />
          <button
            aria-controls='menu'
            onClick={closeDrawer}
            className='active:scale-95'
          >
            <iconComponents.util.CancelIcon
              stroke='#000'
              width={24}
              height={24}
            />
          </button>
        </header>

        <main className='w-full mt-4 h-[calc(100svh_-_64px)] flex-1  flex flex-col justify-between'>
          <ul role='menu' className='flex flex-col justify-start py-2 gap-4'>
            {menuOptions
              .filter((opt) => (opt.name === 'Message' ? false : true))
              .map((el) =>
                el.hasPermission ? (
                  <Permission
                    key={el.name}
                    noAccess={<></>}
                    permissions={[
                      PermissionNames[el.permissionKey as 'Can View Funds'],
                    ]}
                  >
                    {el.name === 'User Roles & Permissions' &&
                    currentAccount.accountType === 'organisation' ? (
                      <NavItem key={el.name} {...el} />
                    ) : el.name !== 'User Roles & Permissions' ? (
                      <NavItem key={el.name} {...el} />
                    ) : (
                      (null as any)
                    )}
                  </Permission>
                ) : (
                  <NavItem key={el.name} {...el} />
                )
              )}
          </ul>

          <div className='flex-1 w-full flex justify-end justify-self-end  flex-col gap-8 mb-10'>
            <ul
              onClick={closeDrawer}
              role='menu'
              className='flex flex-col justify-end  gap-2'
            >
              {/* <NavItem
                icon={iconComponents.navigation.SettingIcon}
                name='Settings'
                url={`/app/setting`}
              /> */}
              <NavItem
                icon={Notifications}
                name='Notifications'
                url={`/app/${Pages.Notification}`}
                hasPermission={false}
              />
            </ul>

            <UserInfo onClose={closeDrawer} />
          </div>
        </main>
      </nav>
    </section>
  )
}

export default MobileMenu
