import { useAuthActionsContext } from '@/contexts/AuthContextProvider'
import { useNavigate } from 'react-router'
import { Logout } from '../authApis'
import { Pages } from '@/routes/route.constant'

const useLogout = () => {
  const { logOut } = useAuthActionsContext()
  const navigate = useNavigate()
  const logout = async () => {
    try {
      await Logout()
    } catch (err) {
      console.log(err)
    }
    logOut()
    await navigator.credentials.preventSilentAccess()
    navigate(`/auth/${Pages.SignIn}`)
  }
  return logout
}

export default useLogout
