import { PolymorphicComponentProps } from '@/types/polymorphic.type'
import clsx from 'clsx'
import { forwardRef } from 'react'
import DescendantContextProvider from './contexts/DescendantContext'
import TabContextProvider from './contexts/TabsContext'

type TabsProps<C extends React.ElementType> = PolymorphicComponentProps<
  C,
  {
    children: React.ReactNode
    className?: string
  }
>
const Tabs = forwardRef<React.ElementType, TabsProps<React.ElementType>>(
  (
    {
      as: Element = 'div',
      children,
      className,
      defaultValue,
      value,
      onChange,
      ...others
    },
    ref
  ) => {
    const INTERNAL_CLASSNAME = 'Tabs__tabs'
    const Component = Element || 'div'
    return (
      <TabContextProvider
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
      >
        <DescendantContextProvider>
          <Component
            className={clsx(INTERNAL_CLASSNAME, className || '')}
            ref={ref}
            {...others}
          >
            {children}
          </Component>
        </DescendantContextProvider>
      </TabContextProvider>
    )
  }
)

Tabs.displayName = 'Tabs'

export default Tabs
