import Title from '@/common/Title'
import Typography from '@/common/Typography'
import PersonalInformation from './components/PersonalInformation'
import VerifyEmail from './components/VerifyEmail'

const VerifyAccount = () => {
  return (
    <>
      <article className='w-full flex-1 flex flex-col gap-6 md:px-[92px] md:pt-6 pb-10 overflow-auto '>
        <header className='w-full flex items-center justify-center'>
          <Title>Verify Account</Title>
          <hgroup className='flex flex-col justify-center items-center'>
            <Typography as='h1' heading='xl' className='text-center'>
              Sign Up
            </Typography>
            <Typography
              as='p'
              paragraph='lg'
              className='text-center text-sifuse-shades-500'
            >
              Description text
            </Typography>
          </hgroup>
        </header>

        <PersonalInformation onNext={() => {}} />
        <footer className='w-full flex self-stretch items-end justify-center gap-1 flex-1'>
          <Typography button='md' className='text-sifuse-shades-500'>
            Already have an account ?
          </Typography>
          <button
            type='button'
            className='hover:underline font-semibold text-base leading-6 text-sifuse-main-secondary'
          >
            Sign in
          </button>
        </footer>
      </article>
      <VerifyEmail />
    </>
  )
}

export default VerifyAccount
