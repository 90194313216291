import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import useMediaQuery from '@/hooks/useMediaQuery'
import { Pages } from '@/routes/route.constant'
import { useNavigate } from 'react-router'
import { useGetStartups } from '@/store/startupStore'
import useDebounce from '@/hooks/useDebounce'
import ApiState from '@/common/ApiState'
import StartupCard from './StartupCard'
import EmptyScreen from './EmptyScreen'
import { useState } from 'react'
import SearchInput from '@/common/Input/SearchInput'
import Permission from '@/common/permissions/Permission'
import { PermissionNames } from '@/common/permissions/permission.constant'

const ExploreStartups = () => {
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const isLaptop = useMediaQuery('(max-width: 640px)')
  const debouncedSearch = useDebounce(search || '', 300)
  const { data: firstPageStartups, isLoading: isLoadingStartups } =
    useGetStartups({ page: 1, query: debouncedSearch })
  return (
    <section className='flex w-full flex-col gap-6 '>
      <header className='flex flex-col items-start gap-3  md:flex-row justify-between md:items-center w-full'>
        <hgroup className='flex flex-col items-start gap-1 whitespace-nowrap'>
          <Typography as='h3' heading='sm' className='text-sifuse-shades-900'>
            Explore Businesses
          </Typography>
          <Typography as='p' paragraph='sm' color='var(--shades600)'>
            See all businesses on the platform
          </Typography>
        </hgroup>
        <section className='flex gap-[13px] items-center content-start w-full md:w-auto  max-md:flex-wrap'>
          <SearchInput
            style={{
              width: '272px',
              height: '48px',
            }}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
          <div className='flex gap-[13px] items-center'>
            <Button
              color='primary'
              size={isLaptop ? 'sm' : 'lg'}
              className='min-w-[128px]'
              onClick={() =>
                navigate(`/app/${Pages.StartupDiscovery}/${Pages.ExploreAll}`)
              }
            >
              Explore All Businesses
            </Button>
            <Permission
              permissions={[PermissionNames['Can Manage Businesses']]}
              noAccess={<></>}
            >
              <Button
                onClick={() =>
                  navigate(
                    `/app/${Pages.StartupDiscovery}/${Pages.ScreeningCriteria}`
                  )
                }
                color='white'
                size={isLaptop ? 'sm' : 'lg'}
                className='whitespace-nowrap min-w-fit'
              >
                Set My Screening Criteria
              </Button>
            </Permission>
          </div>
        </section>
      </header>
      <main className='w-full flex-1 h-full'>
        <ApiState
          isLoading={isLoadingStartups}
          isEmpty={!firstPageStartups?.pages?.[0]?.count}
          emptyComponent={<EmptyScreen />}
        >
          <ul className='w-full gap-y-4 gap-x-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
            {Array.isArray(firstPageStartups?.pages?.[0]?.results) &&
              (firstPageStartups?.pages?.[0]?.results || []).map((startup) => (
                <li className='w-full' key={startup.id}>
                  <StartupCard
                    data={startup}
                    onView={() =>
                      navigate(
                        `/app/${Pages.StartupDiscovery}/${startup.name}`,
                        {
                          state: { from: 'summary', id: startup.id },
                        }
                      )
                    }
                  />
                </li>
              ))}
          </ul>
        </ApiState>
      </main>
    </section>
  )
}

export default ExploreStartups
