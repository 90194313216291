import iconComponents from '@/assets/icons/iconComponents'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import Modal from '@/common/modal/Modal'
import CircularProgress from '@/common/spinners/CircularProgress'
import { ToastNotify } from '@/common/toastManager'
import { useAuthActionsContext } from '@/contexts/AuthContextProvider'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import useQueryParams from '@/hooks/useQueryParams'
import { Pages } from '@/routes/route.constant'
import { useSendVerifcationLink, useVerifyAccount } from '@/store/authStore'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { flushSync } from 'react-dom'
import { useNavigate } from 'react-router'

const VerifyEmail = () => {
  const { token, uid } = useQueryParams()
  const [apiState, setApiState] = useState<'pending' | 'resolved' | 'rejected'>(
    'pending'
  )
  const navigate = useNavigate()
  const { setAuthState } = useAuthActionsContext()
  const {
    mutateAsync: resendVerificationAction,
    isLoading: isLoadingResending,
  } = useSendVerifcationLink()
  const { mutateAsync: verifyAccountAction } = useVerifyAccount()

  const resendLinkHandler = async () => {
    if (!uid) {
      ToastNotify('info', { message: 'Kindly try the link again' })
      return
    }
    const { error, response } = await withAsync(() => {
      return resendVerificationAction({ uid })
    })
    console.log(error, response)
    if (error) {
      errorHandler(error, 'Invalid Link')
      return
    }
    if (response) {
      ToastNotify('success', {
        message: response.data.message || 'Link successfully sent.',
      })
    }
  }
  useEffect(() => {
    const verifyAccountHandler = async () => {
      if (!uid || !token) {
        console.log(uid, token, 'Token')
        ToastNotify('info', { message: 'Kindly try the link again.' })
        return
      }

      if (apiState === 'resolved' || apiState === 'rejected') return
      const { error, response } = await withAsync(() => {
        return verifyAccountAction({ uid, token })
      })

      if (error) {
        return flushSync(() => setApiState('rejected'))
      }
      if (response) {
        flushSync(() => setApiState('resolved'))
        flushSync(() =>
          setAuthState({
            ...response.data.data,
          } as any)
        )
        setTimeout(() => {
          navigate(`/success`, { state: { from: Pages.SignUp }, replace: true })
        }, 10000)
      }
    }
    verifyAccountHandler()
  }, [apiState, token, uid, navigate, setAuthState, verifyAccountAction])
  const CurrentState = useMemo(
    () => ({
      pending: <PendingUi />,
      resolved: <ResolvedUi />,
      rejected: <RejectedUi />,
    }),
    []
  )
  return (
    <Modal variant='md' enableWarning={false} onClose={() => {}} open>
      <main
        className={clsx(
          'm-6 flex flex-col  py-12 pb-2 items-center justify-center gap-4 self-stretch',
          ['pending'].includes(apiState) ? '' : ' gap-5'
        )}
      >
        {CurrentState[apiState]}
      </main>
      {apiState === 'rejected' && (
        <footer className='mb-10 w-full flex items-center justify-center'>
          <Button
            startIcon={
              <svg
                width='24'
                height='25'
                viewBox='0 0 24 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M21.6904 3.21C21.6104 3.03 21.4704 2.88 21.2804 2.8C21.1904 2.77 21.1004 2.75 21.0004 2.75H17.0004C16.5904 2.75 16.2504 3.09 16.2504 3.5C16.2504 3.91 16.5904 4.25 17.0004 4.25H19.1904L14.4704 8.97C14.1804 9.26 14.1804 9.74 14.4704 10.03C14.6204 10.18 14.8104 10.25 15.0004 10.25C15.1904 10.25 15.3804 10.18 15.5304 10.03L20.2504 5.31V7.5C20.2504 7.91 20.5904 8.25 21.0004 8.25C21.4104 8.25 21.7504 7.91 21.7504 7.5V3.5C21.7504 3.4 21.7304 3.31 21.6904 3.21Z'
                  fill='#000014'
                />
                <path
                  d='M8.47 14.9699L3.75 19.6899V17.4999C3.75 17.0899 3.41 16.7499 3 16.7499C2.59 16.7499 2.25 17.0899 2.25 17.4999V21.4999C2.25 21.5999 2.27 21.6899 2.31 21.7899C2.39 21.9699 2.53 22.1199 2.72 22.1999C2.8 22.2299 2.9 22.2499 3 22.2499H7C7.41 22.2499 7.75 21.9099 7.75 21.4999C7.75 21.0899 7.41 20.7499 7 20.7499H4.81L9.53 16.0299C9.82 15.7399 9.82 15.2599 9.53 14.9699C9.24 14.6799 8.76 14.6799 8.47 14.9699Z'
                  fill='#000014'
                />
                <path
                  d='M2.2 15.25C1.85 15.25 1.54 15 1.47 14.65C1.33 13.95 1.25 13.22 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C12.73 1.75 13.46 1.82 14.17 1.97C14.58 2.05 14.84 2.45 14.76 2.85C14.68 3.26 14.27 3.51 13.88 3.44C13.25 3.31 12.63 3.25 12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 13.12 2.81 13.75 2.94 14.35C3.02 14.76 2.76 15.15 2.35 15.23C2.3 15.25 2.25 15.25 2.2 15.25Z'
                  fill='#292D32'
                />
                <path
                  d='M12 23.2498C11.27 23.2498 10.54 23.1798 9.83003 23.0298C9.42003 22.9498 9.16003 22.5498 9.24003 22.1498C9.32003 21.7398 9.73003 21.4898 10.12 21.5598C10.73 21.6798 11.36 21.7498 11.99 21.7498C17.09 21.7498 21.24 17.5998 21.24 12.4998C21.24 11.8798 21.18 11.2598 21.06 10.6498C20.98 10.2398 21.24 9.84981 21.65 9.76981C22.06 9.68981 22.45 9.94981 22.53 10.3598C22.67 11.0598 22.74 11.7798 22.74 12.4998C22.75 18.4298 17.93 23.2498 12 23.2498Z'
                  fill='#292D32'
                />
              </svg>
            }
            color='grey'
            size='lg'
            className='min-w-[138px]'
            onClick={resendLinkHandler}
            loading={isLoadingResending}
          >
            Resend link
          </Button>
        </footer>
      )}
    </Modal>
  )
}

export default VerifyEmail

const PendingUi = () => {
  return (
    <>
      <Typography as='h4' heading='xs' className='text-sifuse-shades-800'>
        Verifying Email Address
      </Typography>
      <Typography
        as='p'
        paragraph='lg'
        className='text-sifuse-shades-600  text-center max-w-[383px]'
      >
        Please kindly wait while we verify your email...
      </Typography>
      <div className='max-w-[25px] flex justify-center items-center mx-auto'>
        <CircularProgress size='20px' color='#272343' />
      </div>
    </>
  )
}

const ResolvedUi = () => {
  return (
    <>
      <div className='w-full flex items-center justify-center'>
        <iconComponents.util.VerifyIcon />
      </div>
      <Typography as='h4' heading='xs' className='text-sifuse-shades-800'>
        Email Verification Successful
      </Typography>
      <Typography
        as='p'
        paragraph='lg'
        className='text-sifuse-shades-600 text-center max-w-[383px]'
      >
        Your email has been successfully verified, you will be redirected to
        your dashboard shortly
      </Typography>
    </>
  )
}
const RejectedUi = () => {
  return (
    <>
      <div className='w-full flex items-center justify-center'>
        <iconComponents.util.DangerIcon />
      </div>
      <Typography as='h4' heading='xs' className='text-sifuse-shades-800'>
        Email Verification not Successful
      </Typography>
      <Typography
        as='p'
        paragraph='lg'
        className='text-sifuse-shades-600 text-center max-w-[383px]'
      >
        Your email verification was not success, kindly retry this process again
        for the verification
      </Typography>
    </>
  )
}
