import clsx from 'clsx'
import { AllHTMLAttributes } from 'react'
import InputWithAdornment from './InputWithAdornment'

interface ISearch extends AllHTMLAttributes<HTMLInputElement> {}

function SearchInput(props: ISearch) {
  const {
    height,
    placeholder = 'Search',
    className,
    style = {},
    ...rest
  } = props
  return (
    <InputWithAdornment
      style={{
        border: '1px solid var(--shades200)',
        padding: '8px 16px',
        backgroundColor: 'white',
        ...style,
      }}
      className={clsx(
        'py-2.5 px-3.5 bg-sifuse-shades-50 rounded-lg border border-sifuse-shades-200 h-12 min-w-[250px]',
        className
      )}
      left={
        <svg
          width='17'
          height='17'
          viewBox='0 0 17 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M14.0002 8.58724C14.0002 11.8089 11.3885 14.4206 8.16683 14.4206C4.94517 14.4206 2.3335 11.8089 2.3335 8.58724C2.3335 5.36558 4.94517 2.75391 8.16683 2.75391C11.3885 2.75391 14.0002 5.36558 14.0002 8.58724Z'
            fill='#6D6D77'
            stroke='#6D6D77'
          />
          <path
            d='M14.7002 15.5873C14.5802 15.5873 14.4602 15.5406 14.3735 15.4539L13.1335 14.2139C12.9535 14.0339 12.9535 13.7406 13.1335 13.5539C13.3135 13.3739 13.6069 13.3739 13.7935 13.5539L15.0335 14.7939C15.2135 14.9739 15.2135 15.2673 15.0335 15.4539C14.9402 15.5406 14.8202 15.5873 14.7002 15.5873Z'
            fill='#6D6D77'
          />
        </svg>
      }
      height={height as string}
      placeholder={placeholder}
      {...rest}
    />
  )
}
export default SearchInput
