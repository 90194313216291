import { addDataToQueryString } from '@/helpers/strings'
import api from './api'
import { Canceler, PaginatedData, PaginationReq, ServerRes } from './api.types'
import {
  CHATS,
  CHATS_MESSAGES,
  CHAT_MEDIA,
  MARK_CHATS_READ,
  SHARED_DOCUMENT,
  SHARED_LINK,
  SHARED_MEDIA,
  UNREAD_COUNT,
} from './endpoints'

export type Chat = {
  id: number
  chat_partner: Chatpartner
  created: string
  last_message?: Message
  has_media: boolean
  investor_group?: boolean
}

type UserType = 'Investor' | 'Startup' | 'Admin'
interface Chatpartner {
  id: number
  name: string
  avatar?: string
  category?: string
  logo?: string
  user_type: UserType
  startup?: string
  about?: string
}

export type Message = {
  id?: number
  chat: number
  text: string
  media: Media[]
  read: boolean
  created: string
  sender: {
    id: number
    avatar: string
    user_type: UserType
  }
}
export type MessageReqDTO = {
  chat: number
  text?: string
  media?: number[]
}
export type Media = {
  id: number
  chat: number
  media: string
  type: 'Image' | ' Video' | 'Audio' | 'Document'
  thumbnail: string
  name: string
  size: string
  created: string
}
export const GetChats = async (data: PaginationReq) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<ServerRes<PaginatedData<Chat>>>(CHATS + (qs ? `?${qs}` : ''))
  ).data.data
}

export const CreateChat = async (data: {
  recipient: number
  type: 'user' | 'group'
}) => {
  return await api.post<ServerRes<Chat>>(CHATS, data)
}

export const MarkAllChatsAsRead = async () => {
  return await api.patch<ServerRes<any>>(MARK_CHATS_READ, {})
}

export const CreateChatMedia = async (payload: {
  abort?: (cancel: Canceler) => void
  data: FormData
}) => {
  return await api.post<ServerRes<Media>>(CHAT_MEDIA, payload.data, {
    abort: payload.abort,
  })
}

export const DeleteChatMedia = async (data: { media: number }) => {
  return await api.delete<ServerRes<any>>(CHAT_MEDIA + `${data.media}/`)
}
export const GetMessages = async (data: PaginationReq & { chat: number }) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<ServerRes<PaginatedData<Message>>>(
      CHATS_MESSAGES + (qs ? `?${qs}` : '')
    )
  ).data.data
}

export const CreateMessage = async (data: MessageReqDTO) => {
  return await api.post<ServerRes<Message>>(CHATS_MESSAGES, data)
}

export const GetUnreadCount = async () => {
  return (await api.get<ServerRes<{ unread_count: number }>>(UNREAD_COUNT)).data
    .data
}

export const SharedDocument = async (data: { chat: number }) => {
  return (
    await api.get<ServerRes<Media[]>>(SHARED_DOCUMENT + `?chat=${data.chat}`)
  ).data.data
}

export const SharedMedia = async (data: { chat: number }) => {
  return (
    await api.get<ServerRes<Media[]>>(SHARED_MEDIA + `?chat=${data.chat}`)
  ).data.data
}
export const SharedLink = async (data: { chat: number }) => {
  return (
    await api.get<ServerRes<string[]>>(SHARED_LINK + `?chat=${data.chat}`)
  ).data.data
}
